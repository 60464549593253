import React from 'react';
import styles from './Accounting.module.css'; // Adjust the import based on your file structure

const BigSlider = ({ data }) => {
  const currentFrame = data;

  return (
    <div className={styles.sliderCont}>
      <div className={styles.Cont}>
        <div className={styles.SubCont}>

          <div className={styles.avatarname}>
            <div className={styles.Bigname}>
              <h1>{currentFrame.name}</h1>
            </div>
            <div className={styles.BigprofileImg}>
              <img src="/avatar.svg" alt="" />
            </div>
          </div>
          <div className={styles.titlelabelsCont}>
            <div className={styles.Bigtitle}>
              <h1> {currentFrame.title}</h1>
            </div>
            <div className={styles.labelsCont}>
              <div className={styles.Bigstatus}>
                <h1>{currentFrame.status}</h1>
                <svg xmlns="http://www.w3.org/2000/svg" width="41" height="42" viewBox="0 0 41 42" fill="none">
                  <circle cx="20.5" cy="21" r="20.5" fill="#25D366" />
                </svg>
              </div>
              <div className={styles.Bigdepart}>
                <h1>{currentFrame.department}</h1>
              </div>
            </div>

          </div>
          <div className={styles.BigsecondSlideCont}>
            <h1>{currentFrame.task}</h1>
          </div>
          <div className={styles.thirdSlideCont}>

          </div>          </div>
        <div className="Frame427319820" style={{ width: `100%`, height: 173, background: '#FDE5FC', borderTopLeftRadius: 32, borderTopRightRadius: 32, borderBottomRightRadius: 20, borderBottomLeftRadius: 20, justifyContent: 'center', alignItems: 'center', gap: 32, display: 'inline-flex' }}>            <div className="Frame427319810" style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'inline-flex' }}>
          <div className="Frame" style={{ width: 98, height: 98, position: 'relative' }}>
            <img src="/Bigagenda.svg" alt="" />
          </div>
          <div className="Sun181Mon251" style={{ color: 'black', fontSize: 64, fontFamily: 'Noto Sans', fontWeight: '700', wordWrap: 'break-word' }}>{currentFrame.startdate}  :  {currentFrame.enddate} </div>
        </div>
        </div>
      </div></div>
  );
};

export default BigSlider;
