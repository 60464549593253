import { HashRouter as Router, Route, Redirect } from "react-router-dom";
import Home from './Home/LandingPage/Home.jsx';
import SignIn from './Home/Login/Login.jsx';
import Accounting from './Home/LandingPage/Accounting.jsx';

const App = () => {
  return (
    <Router>
      <Route path="/" exact component={Accounting} />
      <Route path="/signin" exact component={SignIn} />
      <Route path="/home" exact component={Home} />
      {/* <Redirect from="/" to="/signin" /> */}
    </Router>
  );
};

export default App;
