import { useState } from "react";
import { useHistory } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";

const SignIn = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);

    const history = useHistory();

    const signIn = async (e) => {
        e.preventDefault();

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);

            // Authentication successful
            console.log("success");

            // Save user session information in localStorage
            localStorage.setItem("user", JSON.stringify(userCredential.user));

            // Redirect to home page upon successful login
            history.push("/home");
        } catch (error) {
            console.error(error);
            setError(error.message); // Display error message to the user
        }
    };

    return (
        <div className="sign-in-container">
            <form onSubmit={signIn}>
                <h1>Log In to your Account</h1>
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                ></input>
                <input
                    type="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                ></input>
                <button type="submit">Log In</button>
                {error && <p className="error-message">{error}</p>}
            </form>
        </div>
    );
};

export default SignIn;
