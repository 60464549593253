import React, { useEffect, useState } from 'react';
import { db } from '../../firebase'; // Assuming you have a 'db' export from firebase.js
import { ref, onValue } from 'firebase/database';
import styles from './Home.module.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
function Home() {
    const [tasks, setTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Track loading state
    useEffect(() => {
        const query = ref(db, "tasks");
        const unsubscribe = onValue(query, (snapshot) => {
            const data = snapshot.val();

            if (data) {
                const taskArray = Object.values(data.Sales);
                setTasks(taskArray);
            } else {
                setTasks([]);
            }

            // Set loading state to false when data is fetched
            setIsLoading(false);
        });

        // Cleanup function to unsubscribe when the component unmounts
        return () => unsubscribe();
    }, []);

    return (
        <>
            <div className={styles.overlay}>
                {isLoading ? (
                    // Show a loading indicator or message while data is being fetched
                    <p>Loading...</p>
                ) : (
                    tasks.length > 0 && (
                        <>
                            <div className={styles.cont}>
                                <div className={styles.leftCont}>
                                    <div className={styles.leftbigCont}>

                                        <Carousel
                                            className={styles.BigCarousel}
                                            autoPlay={true}
                                            showThumbs={false}
                                            showStatus={false}
                                            infiniteLoop={true}
                                            showIndicators={false}
                                            showArrows={false}
                                            interval={3000}

                                        >

                                            <div className={styles.SlideCont}>
                                                <div className={styles.firstSlideCont}>
                                                    <div className={styles.labelsCont}>
                                                        <div className={styles.status}>


                                                        </div>
                                                        <div className={styles.depart}></div>
                                                    </div>
                                                    <div className={styles.avatarname}>

                                                        <div className={styles.name}></div>
                                                        <div className={styles.profileImg}></div>
                                                    </div>

                                                </div>
                                                <div className={styles.secondSlideCont}></div>
                                                <div className={styles.thirdSlideCont}>
                                                    {/* <div className={styles.BigDateFrame} >
                                                        <div className="Frame427319810" style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'inline-flex' }}>
                                                            <div className="Frame" style={{ width: 43, height: 43, position: 'relative' }}>

                                                            </div>
                                                            <div className={styles.StartEndDate} >Sun 18/1  :  Mon 25/1 </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>


                                        </Carousel>

                                    </div>
                                    <div className={styles.leftsmallCont}>
                                        <div className={styles.leftsamll}>
                                            <Carousel

                                                className={styles.Carousel}
                                                autoPlay={true}
                                                showThumbs={false}
                                                showStatus={false}
                                                infiniteLoop={true}
                                                showIndicators={false}
                                                showArrows={false}

                                            >
                                                {tasks.map((task, index) => (
                                                    <div key={index}>
                                                        <strong>Task ID:</strong> {task.creation_time}<br />
                                                        <strong>Employee Name:</strong> {task.employeename}<br />
                                                        <strong>Department:</strong> {task.department}<br />
                                                        <strong>Task:</strong> {task.task}<br />
                                                        <strong>Start Date:</strong> {task.startdate}<br />
                                                        <strong>End Date:</strong> {task.enddate}<br />
                                                        <strong>Progress:</strong> {task.progress ? 'Completed' : 'Not Completed'}<br />

                                                    </div>
                                                ))}
                                            </Carousel>
                                        </div>
                                        <div className={styles.leftsamll}>
                                            <Carousel

                                                className={styles.Carousel}
                                                autoPlay={true}
                                                showThumbs={false}
                                                showStatus={false}
                                                infiniteLoop={true}
                                                showIndicators={false}
                                                showArrows={false}

                                            >
                                                {tasks.map((task, index) => (
                                                    <div key={index}>
                                                        <strong>Task ID:</strong> {task.creation_time}<br />
                                                        <strong>Employee Name:</strong> {task.employeename}<br />
                                                        <strong>Department:</strong> {task.department}<br />
                                                        <strong>Task:</strong> {task.task}<br />
                                                        <strong>Start Date:</strong> {task.startdate}<br />
                                                        <strong>End Date:</strong> {task.enddate}<br />
                                                        <strong>Progress:</strong> {task.progress ? 'Completed' : 'Not Completed'}<br />

                                                    </div>
                                                ))}
                                            </Carousel>
                                        </div>
                                        <div className={styles.leftsamll}>
                                            <Carousel


                                                className={styles.Carousel}
                                                autoPlay={true}
                                                showThumbs={false}
                                                showStatus={false}
                                                infiniteLoop={true}
                                                showIndicators={false}
                                                showArrows={false}

                                            >
                                                {tasks.map((task, index) => (
                                                    <div key={index}>
                                                        <strong>Task ID:</strong> {task.creation_time}<br />
                                                        <strong>Employee Name:</strong> {task.employeename}<br />
                                                        <strong>Department:</strong> {task.department}<br />
                                                        <strong>Task:</strong> {task.task}<br />
                                                        <strong>Start Date:</strong> {task.startdate}<br />
                                                        <strong>End Date:</strong> {task.enddate}<br />
                                                        <strong>Progress:</strong> {task.progress ? 'Completed' : 'Not Completed'}<br />

                                                    </div>
                                                ))}
                                            </Carousel>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.rightCont}>
                                    <div className={styles.subrightCont}>
                                        <div className={styles.subrightCard}>
                                            <Carousel
                                                className={styles.Carousel}
                                                autoPlay={true}
                                                showThumbs={false}
                                                showStatus={false}
                                                infiniteLoop={true}
                                                showIndicators={false}
                                                showArrows={false}
                                                dynamicHeight={true}
                                            >
                                                <div style={{ display: `flex`, flexDirection: `column`, margin: `10px`, height: `100%` }}>
                                                    <div style={{ display: `flex`, flexDirection: `row`, justifyContent: `end` }}>

                                                        <div className={styles.title}>
                                                            <h1>عنوان التاسك</h1>
                                                        </div>
                                                    </div>


                                                    <div style={{ display: `flex`, flexDirection: `row`, justifyContent: `right` }}>
                                                        <div style={{ display: `flex`, flexDirection: `row`, justifyContent: `center`, width: `50%`, gap: `10%` }}>
                                                            <div className={styles.status}>
                                                                <h1>متعلق</h1>
                                                            </div>
                                                            <div className={styles.depart}>
                                                                <h1>سيلز</h1>
                                                            </div></div>
                                                        <div className={styles.name}>
                                                            <h1>محمد طلعت</h1>

                                                        </div>

                                                    </div>

                                                    <div className={styles.desCont}>
                                                        <h1>تنفيذ برنامج لتنظيم التاسكات عشان كل واحد يعرف التاسك بتاعه ويكون فيه تاريخ البدء والانتهاء</h1>
                                                    </div>
                                                    <div className={styles.DateFrame} >
                                                        <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'inline-flex' }}>
                                                            <div style={{ width: 43, height: 43, position: 'relative' }}>

                                                            </div>
                                                            <div className={styles.StartEndDate} >Sun 18/1  :  Mon 25/1 </div>
                                                        </div>
                                                    </div>

                                                </div>


                                            </Carousel>
                                        </div>

                                        <div className={styles.subrightCard}>
                                            <Carousel

                                                className={styles.Carousel}
                                                autoPlay={true}
                                                showThumbs={false}
                                                showStatus={false}
                                                infiniteLoop={true}
                                                showIndicators={false}
                                                showArrows={false}

                                            >
                                                {tasks.map((task, index) => (
                                                    <div key={index}>
                                                        <strong>Task ID:</strong> {task.creation_time}<br />
                                                        <strong>Employee Name:</strong> {task.employeename}<br />
                                                        <strong>Department:</strong> {task.department}<br />
                                                        <strong>Task:</strong> {task.task}<br />
                                                        <strong>Start Date:</strong> {task.startdate}<br />
                                                        <strong>End Date:</strong> {task.enddate}<br />
                                                        <strong>Progress:</strong> {task.progress ? 'Completed' : 'Not Completed'}<br />

                                                    </div>
                                                ))}
                                            </Carousel>
                                        </div>

                                        <div className={styles.subrightCard}>
                                            <Carousel

                                                className={styles.Carousel}
                                                autoPlay={true}
                                                showThumbs={false}
                                                showStatus={false}
                                                infiniteLoop={true}
                                                showIndicators={false}
                                                showArrows={false}

                                            >
                                                {tasks.map((task, index) => (
                                                    <div key={index}>
                                                        <strong>Task ID:</strong> {task.creation_time}<br />
                                                        <strong>Employee Name:</strong> {task.employeename}<br />
                                                        <strong>Department:</strong> {task.department}<br />
                                                        <strong>Task:</strong> {task.task}<br />
                                                        <strong>Start Date:</strong> {task.startdate}<br />
                                                        <strong>End Date:</strong> {task.enddate}<br />
                                                        <strong>Progress:</strong> {task.progress ? 'Completed' : 'Not Completed'}<br />

                                                    </div>
                                                ))}
                                            </Carousel>
                                        </div>

                                    </div>
                                    <div className={styles.subrightCont}>
                                        <div className={styles.subrightCard}>
                                            <Carousel

                                                className={styles.Carousel}
                                                autoPlay={true}
                                                showThumbs={false}
                                                showStatus={false}
                                                infiniteLoop={true}
                                                showIndicators={false}
                                                showArrows={false}

                                            >
                                                {tasks.map((task, index) => (
                                                    <div key={index}>
                                                        <strong>Task ID:</strong> {task.creation_time}<br />
                                                        <strong>Employee Name:</strong> {task.employeename}<br />
                                                        <strong>Department:</strong> {task.department}<br />
                                                        <strong>Task:</strong> {task.task}<br />
                                                        <strong>Start Date:</strong> {task.startdate}<br />
                                                        <strong>End Date:</strong> {task.enddate}<br />
                                                        <strong>Progress:</strong> {task.progress ? 'Completed' : 'Not Completed'}<br />

                                                    </div>
                                                ))}
                                            </Carousel>
                                        </div>
                                        <div className={styles.subrightCard}>
                                            <Carousel

                                                className={styles.Carousel}
                                                autoPlay={true}
                                                showThumbs={false}
                                                showStatus={false}
                                                infiniteLoop={true}
                                                showIndicators={false}
                                                showArrows={false}

                                            >
                                                {tasks.map((task, index) => (
                                                    <div key={index}>
                                                        <strong>Task ID:</strong> {task.creation_time}<br />
                                                        <strong>Employee Name:</strong> {task.employeename}<br />
                                                        <strong>Department:</strong> {task.department}<br />
                                                        <strong>Task:</strong> {task.task}<br />
                                                        <strong>Start Date:</strong> {task.startdate}<br />
                                                        <strong>End Date:</strong> {task.enddate}<br />
                                                        <strong>Progress:</strong> {task.progress ? 'Completed' : 'Not Completed'}<br />

                                                    </div>
                                                ))}
                                            </Carousel>
                                        </div>
                                        <div className={styles.subrightCard}>
                                            <Carousel

                                                className={styles.Carousel}
                                                autoPlay={true}
                                                showThumbs={false}
                                                showStatus={false}
                                                infiniteLoop={true}
                                                showIndicators={false}
                                                showArrows={false}

                                            >
                                                {tasks.map((task, index) => (
                                                    <div key={index}>
                                                        <strong>Task ID:</strong> {task.creation_time}<br />
                                                        <strong>Employee Name:</strong> {task.employeename}<br />
                                                        <strong>Department:</strong> {task.department}<br />
                                                        <strong>Task:</strong> {task.task}<br />
                                                        <strong>Start Date:</strong> {task.startdate}<br />
                                                        <strong>End Date:</strong> {task.enddate}<br />
                                                        <strong>Progress:</strong> {task.progress ? 'Completed' : 'Not Completed'}<br />

                                                    </div>
                                                ))}
                                            </Carousel>
                                        </div>
                                    </div>

                                </div>


                            </div>  </>))}
            </div>
        </>
    );
}

export default Home;
