import React, { useRef, useEffect } from 'react';
import styles from './Accounting.module.css'; // Adjust the import based on your file structure

const PopupCard = ({ Carddata, onClose }) => {
    const currentFrame = Carddata;
    const overlayRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (overlayRef.current && !overlayRef.current.contains(event.target)) {
                // Clicked outside the popup, close it
                onClose();
            }
        };

        // Add event listener to handle clicks outside the popup
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Remove the event listener when the component unmounts
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className={styles.popupContainer}>
            <div
                ref={overlayRef}
                className="Frame427319838"
                style={{
                    position: 'absolute',
                    top: '20vh', // Adjust the top value as needed
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '85vw',

                    background: '#EBF7F9',
                    boxShadow: '4px 4px 0px black',
                    borderRadius: 12,
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    alignItems: 'flex-end',
                    gap: 20,
                    display: 'inline-flex',

                    maxHeight: `63vh`
                }}
            >
                <div style={{ display: `flex`, flexDirection: `row-reverse`, alignItems: `center`, gap: `2.7vw`, width: `81vw`, justifyContent: `space-between`, marginTop: `2.5vh`, marginRight: `1vh`, height: `10vw` }}>
                    <div className={styles.profileImg} style={{ height: `auto`, width: `auto` }} >
                        <img style={{ height: `7vh` }} src="/avatar.svg" alt="" />
                    </div>
                    <div className={styles.name}>
                        <h1 style={{ fontSize: `5vw` }}>{currentFrame.name}</h1>
                    </div>
                    <div>
                        <div className={styles.depart} style={{ width: `18vw`, height: `4.5vh`, display: `flex`, flexDirection: `row`, padding: `0vh 0vh 0vh .5vh` }}>
                            <h1 style={{ fontSize: `4vw`, margin: `0` }}>{currentFrame.department}</h1>
                        </div>
                    </div>
                    <div>
                        <div className={styles.status} style={{ background: currentFrame.status === 'متعلق' ? '#FFE0BB' : '#BDF6D2', width: `18vw`, height: `4.5vh`, display: `flex`, flexDirection: `row`, padding: `0vh 0vh 0vh .5vh` }}>
                            <h1 style={{ fontSize: `4vw`, margin: `0` }}>{currentFrame.status}</h1>

                            {currentFrame.status === 'متعلق' ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
                                    <circle cx="4" cy="4.5" r="4" fill="#F89118" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
                                    <circle cx="4" cy="4.5" r="4" fill="#25D366" />
                                </svg>
                            )}
                        </div>


                    </div>
                </div>
                <div>
                    <h1 style={{
                        fontSize: '3.5vw',
                        overflow: 'hidden',
                        fontFamily: "Noto Sans Arabic",
                        whiteSpace: 'normal',
                        width: `100%`,
                        display: `flex`,
                        justifyContent: `end`,

                    }}>{currentFrame.title}</h1>
                </div>
                <div style={{
                    width: '80vw',
                    height: `100%`,
                    display: 'flex',
                    overflow: 'auto',
                    alignSelf: `center`,


                }}>
                    <h1 style={{
                        fontSize: '3.5vw',

                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                        width: `100%`,
                        display: `flex`,
                        justifyContent: `end`,
                        fontFamily: "Noto Sans Arabic",

                    }}>{currentFrame.task}</h1>
                </div>
                <div style={{
                    display: `flex`,
                    alignSelf: `center`,

                }}>
                    <div className="Frame427319813" style={{ overflow: `hidden`, alignSelf: `center`, padding: 8, background: '#FDE5FC', borderTopLeftRadius: 20, borderTopRightRadius: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'inline-flex', width: `80vw` }}>
                        <div className="Frame427319810" style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'flex', margin: `0` }}>
                            <div className="Frame" style={{ width: 20, height: 20, position: 'relative', display: `flex` }}>
                                <img style={{ width: 20, height: 20, position: 'relative' }} src="/agenda.svg" alt="" />
                            </div>
                            <div className="Sun181Mon251" style={{ color: 'black', fontSize: 12, fontFamily: 'Noto Sans', fontWeight: '600', wordWrap: 'break-word' }}>{currentFrame.startdate}  :  {currentFrame.enddate} </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopupCard
