// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {

    apiKey: "AIzaSyDLh5rTzpNVIf0bKtuE91snK_vhwV8BXQs",

    authDomain: "banknote-b2545.firebaseapp.com",

    databaseURL: "https://banknote-b2545-default-rtdb.firebaseio.com",

    projectId: "banknote-b2545",

    storageBucket: "banknote-b2545.appspot.com",

    messagingSenderId: "416781960647",

    appId: "1:416781960647:web:5047500b5376dd04b8c5e2",

    measurementId: "G-TJN13G2Y7C"

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getDatabase(app);
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;
export { db };