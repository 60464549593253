import React, { useState, useEffect } from 'react';
import Slider from './Slider';
import BigSlider from './BigSlider';
import Card from './card';
import styles from './Accounting.module.css';
import { useLocation } from 'react-router-dom';
import '../../index.css';
import { db } from '../../firebase'; // Assuming you have a 'db' export from firebase.js
import { ref, onValue } from 'firebase/database';

const Accounting = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialPageNumber = queryParams.get('pagenumb') || 1;

  const [currentSlide, setCurrentSlide] = useState(initialPageNumber - 1);


  const slideWidth = 19; // Adjust the width as needed (e.g., 20%)
  const BigslideWidth = 55; // Adjust the width as needed (e.g., 20%)
  const [framesData, setFramesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const totalSlides = framesData.length;
  const autoScrollInterval = 5000; // Adjust the interval time in milliseconds (e.g., 5000 for 5 seconds)
  const [autoScroll, setAutoScroll] = useState(true);
  useEffect(() => {
    const query = ref(db, "tasks");
    const unsubscribe = onValue(query, (snapshot) => {
      const data = snapshot.val();

      if (data) {
        const taskArray = Object.values(data.Sales); // Assuming "Sales" is the department you're interested in

        const updatedFramesData = taskArray.map((task) => ({
          name: task.employeename || '', // Assuming employeename can be empty
          title: task.title || '', // Replace 'title' with the actual property if it exists in your data
          status: task.progress ? 'متعلق' : 'شغال',
          department: task.department || '',
          task: task.task || '',
          startdate: task.startdate || '',
          enddate: task.enddate || '',
        }));

        setFramesData(updatedFramesData);
      } else {
        setFramesData([]);
      }

      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);



  useEffect(() => {
    let interval;

    if (autoScroll) {
      interval = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
      }, autoScrollInterval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [autoScroll, totalSlides]);

  const sliderContainerStyle = {
    width: '100%', // Adjust the width as needed
    overflow: 'hidden',
    margin: '0 auto', // Center the slider
    height: '100%'
  };

  const slidesrowStyle = {
    display: 'flex',
    flexDirection: 'row',
    width: `${totalSlides * slideWidth}vw`, // Adjusted to vw units
    transform: `translateX(-${currentSlide * slideWidth}vw)`, // Adjusted to vw units
    transition: 'transform 0.5s ease-in-out',
    height: '100%'
  };
  const BigslidesrowStyle = {
    display: 'flex',
    flexDirection: 'row',
    width: `${totalSlides * BigslideWidth}vw`, // Adjusted to vw units
    transform: `translateX(-${currentSlide * BigslideWidth}vw)`, // Adjusted to vw units
    transition: 'transform 0.5s ease-in-out',
    height: '100%'
  };

  const slideStyle = {
    flex: '0 0 auto',
    width: `${slideWidth}vw`, // Adjusted to vw units
  };
  const BigslideStyle = {
    flex: '0 0 auto',
    width: `${BigslideWidth}vw`, // Adjusted to vw units
  };

  return (
    <div>
      <div style={{ marginRight: `2%`, marginLeft: `2%`, marginTop: `4%` }} className={styles.desktopVersion}>
        <div className='row mb-4'>
          <div className='col-7'>
            <div className={styles.BigsliderFrame}>
              {isLoading ? (
                <div style={{ fontSize: `100px` }}>Loading...</div>
              ) : (
                <div style={sliderContainerStyle}>
                  <div style={BigslidesrowStyle}>
                    {framesData.map((data, index) => (
                      <div key={index} style={BigslideStyle}>
                        <BigSlider data={data} />
                      </div>
                    ))}
                  </div>

                </div>
              )}
            </div>
          </div>
          <div className='col-5'>
            <div className='row mb-4'>
              <div className='col-6'>
                <div className={styles.sliderFrame}>
                  {isLoading ? (
                    <div style={{ fontSize: `100px` }}>Loading...</div>
                  ) : (
                    <div style={sliderContainerStyle}>
                      <div style={slidesrowStyle}>
                        {framesData.map((data, index) => (
                          <div key={index} style={slideStyle}>
                            <Slider data={data} />
                          </div>
                        ))}
                      </div>

                    </div>)}
                </div>
              </div>
              <div className='col-6'>
                <div className={styles.sliderFrame}>
                  {isLoading ? (
                    <div style={{ fontSize: `100px` }}>Loading...</div>
                  ) : (
                    <div style={sliderContainerStyle}>
                      <div style={slidesrowStyle}>
                        {framesData.map((data, index) => (
                          <div key={index} style={slideStyle}>
                            <Slider data={data} />
                          </div>
                        ))}
                      </div>

                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-6'>
                <div className={styles.sliderFrame}>
                  {isLoading ? (
                    <div style={{ fontSize: `100px` }}>Loading...</div>
                  ) : (
                    <div style={sliderContainerStyle}>
                      <div style={slidesrowStyle}>
                        {framesData.map((data, index) => (
                          <div key={index} style={slideStyle}>
                            <Slider data={data} />
                          </div>
                        ))}
                      </div>

                    </div>)}
                </div>
              </div>
              <div className='col-6'>
                <div className={styles.sliderFrame}>
                  {isLoading ? (
                    <div style={{ fontSize: `100px` }}>Loading...</div>
                  ) : (
                    <div style={sliderContainerStyle}>
                      <div style={slidesrowStyle}>
                        {framesData.map((data, index) => (
                          <div key={index} style={slideStyle}>
                            <Slider data={data} />
                          </div>
                        ))}
                      </div>

                    </div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row' style={{ height: `25%` }}>
          <div className='col-7'>
            <div className='row'>
              <div className='col-4'>
                <div className={styles.sliderFrame}>
                  {isLoading ? (
                    <div style={{ fontSize: `100px` }}>Loading...</div>
                  ) : (
                    <div style={sliderContainerStyle}>
                      <div style={slidesrowStyle}>
                        {framesData.map((data, index) => (
                          <div key={index} style={slideStyle}>
                            <Slider data={data} />
                          </div>
                        ))}
                      </div>

                    </div>)}
                </div>
              </div>
              <div className='col-4'>
                <div className={styles.sliderFrame}>
                  {isLoading ? (
                    <div style={{ fontSize: `100px` }}>Loading...</div>
                  ) : (
                    <div style={sliderContainerStyle}>
                      <div style={slidesrowStyle}>
                        {framesData.map((data, index) => (
                          <div key={index} style={slideStyle}>
                            <Slider data={data} />
                          </div>
                        ))}
                      </div>

                    </div>)}
                </div>
              </div>
              <div className='col-4'>
                <div className={styles.sliderFrame}>
                  {isLoading ? (
                    <div style={{ fontSize: `100px` }}>Loading...</div>
                  ) : (
                    <div style={sliderContainerStyle}>
                      <div style={slidesrowStyle}>
                        {framesData.map((data, index) => (
                          <div key={index} style={slideStyle}>
                            <Slider data={data} />
                          </div>
                        ))}
                      </div>

                    </div>)}
                </div>
              </div>
            </div>
          </div>
          <div className='col-5'>
            <div className='row'>
              <div className='col-6'>
                <div className={styles.sliderFrame}>
                  {isLoading ? (
                    <div style={{ fontSize: `100px` }}>Loading...</div>
                  ) : (
                    <div style={sliderContainerStyle}>
                      <div style={slidesrowStyle}>
                        {framesData.map((data, index) => (
                          <div key={index} style={slideStyle}>
                            <Slider data={data} />
                          </div>
                        ))}
                      </div>

                    </div>)}
                </div>
              </div>
              <div className='col-6'>
                <div className={styles.sliderFrame}>
                  {isLoading ? (
                    <div style={{ fontSize: `100px` }}>Loading...</div>
                  ) : (
                    <div style={sliderContainerStyle}>
                      <div style={slidesrowStyle}>
                        {framesData.map((data, index) => (
                          <div key={index} style={slideStyle}>
                            <Slider data={data} />
                          </div>
                        ))}
                      </div>

                    </div>)}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className={styles.mobileVersion} style={{ display: `flex`, justifyContent: `center`, flexDirection: `column`, width: `100%` }}>
        {isLoading ? (
          <div style={{ fontSize: `20px`, textAlign: 'center', marginTop: '20px' }}>Loading...</div>
        ) : (
          <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '10px 0', justifyContent: `center`, width: `100%`, display: `flex`, gap: `3vh`, alignItems: `center`, flexDirection: `column`, marginTop: `5vh`, marginBottom: `5vh` }}>
            {framesData.map((data, index) => (
              <Card key={index} data={data} />
            ))}
          </div>
        )}
      </div>

    </div>

  );
};

export default Accounting;
