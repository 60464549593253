import React from 'react';
import styles from './Accounting.module.css'; // Adjust the import based on your file structure

const Slider = ({ data }) => {
  const currentFrame = data;

  return (
    <div className={styles.sliderCont}>
      <div className={styles.Cont}>
        <div className={styles.SubCont}>
          <div className={styles.avatarname}>
            <div className={styles.name}>
              <h1>{currentFrame.name}</h1>
            </div>
            <div className={styles.profileImg}>
              <img src="/avatar.svg" alt="" />
            </div>
          </div>
          <div className={styles.titlelabelsCont}>
            <div className={styles.title}>
              <h1>{currentFrame.title}</h1>
            </div>
            <div className={styles.labelsCont}>
              <div className={styles.status} style={{ background: currentFrame.status === 'متعلق' ? '#FFE0BB' : '#BDF6D2', }}>
                <h1 >{currentFrame.status}</h1>

                {currentFrame.status === 'متعلق' ? (
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <circle cx="10" cy="10" r="10" fill="#F89118" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <circle cx="10" cy="10" r="10" fill="#25D366" />
                  </svg>
                )}
              </div>
              <div className={styles.depart}>
                <h1>{currentFrame.department}</h1>
              </div>
            </div>

          </div>
          <div className={styles.secondSlideCont}>
            <h1>{currentFrame.task}</h1>
          </div>
          <div className={styles.thirdSlideCont}>

          </div>

        </div>
        <div className="Frame427319813" style={{ width: `100%`, height: 99, padding: 8, background: '#FDE5FC', borderTopLeftRadius: 20, borderTopRightRadius: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 8, display: 'inline-flex' }}>
          <div className="Frame427319810" style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'inline-flex' }}>
            <div className="Frame" style={{ width: 43, height: 43, position: 'relative' }}>
              <img src="/agenda.svg" alt="" />
            </div>
            <div className="Sun181Mon251" style={{ color: 'black', fontSize: 28, fontFamily: 'Noto Sans', fontWeight: '600', wordWrap: 'break-word' }}>{currentFrame.startdate}  :  {currentFrame.enddate} </div>
          </div>
        </div>
      </div></div>
  );
};

export default Slider;
